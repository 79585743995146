import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeClassButton } from '@shared/models/button.model';

@Component({
  selector: 'yoda-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  public typeClassButton = TypeClassButton;
  @Input() label = '';
  @Input() icon = '';
  @Input() typeClass: TypeClassButton = this.typeClassButton.cancel;
  @Input() type = '';
  @Input() disabled = true;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  onClick() {
    this.clicked.emit(this.typeClass);
  }

  classButton() {
    switch (this.typeClass) {
      case this.typeClassButton.cancel: {
        if (!this.disabled) return 'cancel-enabled';
        else return '';
      }
      case this.typeClassButton.previous: {
        if (this.disabled) return 'previous-disabled';
        else return 'previous-enabled';
      }
      case this.typeClassButton.next: {
        if (this.disabled) return 'next-disabled';
        else return 'next-enabled';
      }
      case this.typeClassButton.create: {
        if (this.disabled) return 'create-disabled';
        else return 'create-enabled';
      }
      case this.typeClassButton.confirm: {
        if (this.disabled) return 'confirm-disabled';
        else return 'confirm-enabled';
      }
      case this.typeClassButton.preview: {
        if (this.disabled) return 'preview-disabled';
        else return 'preview-enabled';
      }
      case this.typeClassButton.submit_for_approval: {
        if (this.disabled) return 'submit_for_approval-disabled';
        else return 'submit_for_approval-enabled';
      }
      case this.typeClassButton.save_draft: {
        if (this.disabled) return 'save_draft-disabled';
        else return 'save_draft-enabled';
      }
      case this.typeClassButton.back: {
        if (this.disabled) return 'back-disabled';
        else return 'back-enabled';
      }
      case this.typeClassButton.remove: {
        if (this.disabled) return 'remove-disabled';
        else return 'remove-enabled';
      }
      case this.typeClassButton.default: {
        if (this.disabled) return 'default-disabled';
        else return 'default-enabled';
      }
      case this.typeClassButton.reject: {
        if (this.disabled) return 'reject-disabled';
        else return 'reject-enabled';
      }
      default: {
        return '';
      }
    }
  }
}
